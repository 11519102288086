import React from "react";
import "../../styles/components/home/Cards.css";

const Cards = ({ src, text }) => {
  return (
    <>
      <div className="cards w-full md:w-1/3 mb-4 md:mb-0 px-3 py-3 h-card">
        <figure className="icon-wrapper dm-icons mx-auto overflow-hidden mb-2">
          <img src={src} alt="image" />
        </figure>

        <p className="text-center w-3/4 md:w-full mx-auto text-sm md:text-base mt-5">
          {text}
        </p>
      </div>
    </>
  );
};

export default Cards;
