import React, { useEffect, useState } from "react";
import * as Inputs from "../inputs";
import axios from "axios";

const FormStepOne = ({ register, error }) => {
  const [country, setCountry] = useState([]);

  const [sortedCountry, setSortedCountry] = useState([]);

  let url = `https://restcountries.com/v3.1/region/africa`;

  useEffect(() => {
    const fetchApi = async () => {
      try {
        const result = await axios({
          method: "GET",
          url: url,
        });

        const data = await result.data;

        setCountry(
          data.sort((p1, p2) =>
            p1.name.common > p2.name.common
              ? 1
              : p1.name.common < p2.name.common
              ? -1
              : 0
          )
        );
        return;
      } catch (error) {
        console.log("an error occurred");
      }
    };
    fetchApi();
  }, []);

  return (
    <>
      <Inputs.PrimarySelect
        label="from"
        name="location"
        options={country.map((item, index) => {
          return (
            <option value={item.name.common} key={index}>
              {item.name.common}
            </option>
          );
        })}
        option="Select country"
        register={register}
        error={error?.location}
        message="Please select a valid location"
      />
      <Inputs.PrimarySelect
        label="to"
        name="destination"
        options={country.map((item, index) => {
          return (
            <option value={item.name.common} key={index}>
              {item.name.common}
            </option>
          );
        })}
        option="Select country"
        register={register}
        error={error?.destination}
        message="Please select a valid destination"
      />
    </>
  );
};

export default FormStepOne;
