export const homeCard = [
  {
    id: "1",
    img: "/assets/clipboard.svg",
    text: "Apply to Bodales, declare goods and destination.",
  },
  {
    id: "2",
    img: "/assets/home.svg",
    text: "Pay dues and processing fees.",
  },
  {
    id: "3",
    img: "/assets/truck.svg",
    text: "Get permit, start moving.",
  },
];

export const categories = [
  {
    id: 1,
    category: "Animal/Animal products",
  },
  {
    id: 2,
    category: "Chemicals & Allied industries",
  },
  {
    id: 3,
    category: "Foodstuffs",
  },
  {
    id: 4,
    category: "Footwears/Headgears",
  },
  {
    id: 5,
    category: "Machinery/Electrical",
  },
  {
    id: 6,
    category: "Metals",
  },
  {
    id: 7,
    category: "Mineral Products",
  },
  {
    id: 8,
    category: "Miscellaneous",
  },

  {
    id: 9,
    category: "Plastics/Rubbers",
  },

  {
    id: 10,
    category: "Raw hides and leather",
  },

  {
    id: 11,
    category: "Stone/Glass",
  },

  {
    id: 12,
    category: "Textiles",
  },

  {
    id: 13,
    category: "Transportation",
  },

  {
    id: 14,
    category: "Vegetables Products",
  },
  {
    id: 15,
    category: "Wood and Wood products",
  },
];

export const accountType = ["Individual", "Company"];
