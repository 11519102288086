import React from "react";

const PrimarySelect = ({
  label,
  options,
  option,
  name,
  register,
  error,
  message,
}) => {
  return (
    <>
      <label
        htmlFor={name}
        className="uppercase text-sm w-full  text-light-formtext"
      >
        {label}
      </label>

      <p className="mt-3 text-sm text-custom-red">{error && message}</p>

      <div
        className={`w-full mt-2 mb-5 rounded-md overflow-hidden p-3 h-14 bg-input-bg ${
          error && "border border-custom-red"
        }`}
      >
        <select
          {...register(name)}
          id={name}
          className="w-full h-full bg-transparent text-light-formtext outline-none cursor-pointer"
        >
          <option value="">{option}</option>
          {options}
        </select>
      </div>
    </>
  );
};

// {
//   options.map((item, index) => {
//     return (
//       <option value="jjh" key={index}>
//         {item[keyLabel]}
//       </option>
//     );
//   });
// }

export default PrimarySelect;
