import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";

const Navbar = ({ children }) => {
  const [showMenu, setShowMenu] = useState(false);

  const displayMenu = () => {
    setShowMenu(true);
  };

  const hideMenu = () => {
    setShowMenu(false);
  };
  return (
    <>
      <section className="nav w-full pt-5">
        <nav className="flex justify-between w-90 mx-auto relative">
          {/* logo and hamburger */}
          <section className="w-full flex  md:w-1/2 justify-between items-center">
            {/* logo */}
            <figure className="logo-container dm-logo border-0">
              <img
                src="/assets/bodales.svg"
                alt="logo"
                className="w-full h-full"
              />
            </figure>

            {/* menu icon */}
            <div className="dm-menu md:hidden">
              <FaBars
                style={{ width: "100%", height: "100%" }}
                onClick={displayMenu}
              />
            </div>
          </section>

          {/* nav menu */}
          <section
            className={`nav-menu md:w-full fixed md:flex bg-white top-0 bottom-0 right-0 md:inset-0 md:relative ${
              showMenu && "active-nav"
            }`}
          >
            <div className="flex w-full mb-3 md:hidden">
              <FaTimes onClick={hideMenu} />
            </div>
            <ul className="w-full md:flex md:justify-end items-center">
              <li className="my-5 capitalize text-sm md:text-md font-semibold md:pr-1 md:border-r-2 border-font-color md:my-0">
                english
              </li>
              <li className="my-5 capitalize text-sm md:text-md font-semibold md:pl-1 md:my-0">
                french
              </li>
            </ul>
          </section>
        </nav>
      </section>
      {children}
    </>
  );
};

export default Navbar;
