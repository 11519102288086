import React from "react";
import * as Inputs from "../inputs";
import { categories } from "../../data";

const FormStepTwo = ({ register, error }) => {
  const category = categories.map((item) => {
    return item.category;
  });

  return (
    <>
      <Inputs.PrimarySelect
        label="category"
        name="productCategory"
        options={category.map((item, index) => {
          return (
            <option value={item} key={index}>
              {item}
            </option>
          );
        })}
        option="Select goods category"
        register={register}
        error={error?.productCategory}
        message="Please select a valid category"
      />

      <Inputs.PrimaryInput
        label="description"
        name="productDescription"
        placeholder="Describe your goods"
        register={register}
        error={error?.productDescription}
        message="Please enter a valid description"
      />
    </>
  );
};

export default FormStepTwo;
