import React, { useEffect, useRef } from "react";
import Navbar from "../components/NavBar";
import * as Components from "../components";
import { homeCard } from "../data";

const Home = () => {
  const date = new Date();
  const sliderRef = useRef();

  useEffect(() => {
    const span = sliderRef.current.children;
    let index = 0;
    const slider = () => {
      for (let i = 0; i < span.length; i++) {
        span[i].classList.remove("show-span");
      }
      span[index].classList.add("show-span");

      if (index === span.length - 1) {
        index = 0;
      } else {
        index++;
      }

      setTimeout(slider, 2000);
    };

    slider();
  }, []);

  return (
    <Navbar>
      <section
        className="mx-auto w-90 mt-14 md:mt-24 flex flex-col justify-center"
        style={{ minHeight: "75vh" }}
      >
        <div className="w-full  md:flex md:flex-row-reverse md:items-center">
          <figure className="img-container-sm md:hidden">
            <img
              src="/assets/mobile-hero.svg"
              alt="logo"
              className="w-full h-full"
            />
          </figure>

          <figure className="img-container-md hidden md:flex md:w-1/2">
            <img
              src="/assets/hero-img.svg"
              alt="logo"
              className="w-full h-full"
            />
          </figure>

          <div className="w-full md:w-1/2 mt-5 md:mt-0">
            <h1
              className="capitalize text-2xl sm:text-4xl lg:text-5xl font-bold text-center md:text-left w-90 mb-2 mx-auto md:mx-0 overflow-hidden"
              ref={sliderRef}
            >
              one <span className="text-custom-red slide ">continent</span>
              <span className="text-custom-red slide ">gateway</span>
            </h1>

            <p className="text-center md:text-left w-hero-p text-sm sm:text-base md:text-lg mx-auto md:mx-0">
              Move goods seamlessly across borders in Africa. Get your clearance
              permit in a breeze.
            </p>

            <Components.Button
              textContent="start moving"
              width="w-150"
              addLink={true}
              url="/register"
            />
          </div>
        </div>
      </section>

      <section className="mx-auto w-90 mt-12">
        <h3 className="capitalize w-90 mx-auto text-center font-bold text-2xl md:text-3xl">
          how it works
        </h3>

        <div className="w-full md:flex items-center justify-between md:mt-6 py-3 md:gap-5">
          {homeCard.map((item) => (
            <Components.Cards key={item.id} src={item.img} text={item.text} />
          ))}
        </div>
      </section>

      <footer className="bg-bg-footer w-full py-4 mt-10">
        <div className="w-90 md:flex justify-between mx-auto md:items-center">
          <figure className="footer-logo mx-auto md:mx-0">
            <img
              src="/assets/bodales.svg"
              alt="footer-img"
              className="w-full h-full"
            />
          </figure>

          <section className="w-full  mt-3 md:mt-0">
            <p className="text-center md:text-right font-semibold text-xs">
              <a
                href="mailto:permits@bodales.co"
                className="hover:text-gray-700"
              >
                permits@bodales.co
              </a>
            </p>
            <p className="text-center md:text-right text-sm capitalize">
              &copy;{date.getFullYear()} bodales. all rights reserved.
            </p>
          </section>
        </div>
      </footer>
    </Navbar>
  );
};

export default Home;
