import React from "react";
import { Link } from "react-router-dom";

const NOtFound = () => {
  return (
    <section className="h-screen flex w-full items-end justify-center bg-error-img py-4">
      <div className="h-custom bg-custom-red rounded-lg overflow-hidden mx-auto md:mx-0 mt-5 cursor-pointer w-[200px] ">
        <button className="w-full h-full capitalize text-white">
          <Link to="/">click here to go home</Link>
        </button>
      </div>
    </section>
  );
};

export default NOtFound;
