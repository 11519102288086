import React from "react";
import "../styles/components/Button.css";
import { Link } from "react-router-dom";

const Button = ({ width, textContent, addLink, url }) => {
  return (
    <>
      <div
        className={`h-custom bg-custom-red rounded-lg overflow-hidden mx-auto md:mx-0 mt-5 cursor-pointer hover:bg-transparent border-2 border-custom-red transition duration-200 ease-in ${width}`}
      >
        <button className="w-full h-full capitalize text-white hover:text-custom-red transition duration-200 ease-in">
          {addLink ? (
            <>
              <Link to={url}>{textContent}</Link>
            </>
          ) : (
            textContent
          )}
        </button>
      </div>
    </>
  );
};

export default Button;
