import React, { useState } from "react";
import "../../styles/pages/auth/Register.css";
import { FaArrowLeft } from "react-icons/fa";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import * as Components from "../../components";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const Register = () => {
  // let baseURL = `${process.env.REACT_APP_API}`;
  let baseURL = "https://api.bodales.co/request";
  const navigate = useNavigate();
  const [step, setStep] = useState(1);

  const schema = yup.object({
    step: yup.string(),
    location: yup.string().required(),
    destination: yup.string().required(),
    productCategory: yup.string().when("step", {
      is: "1",
      then: yup.string().required(),
    }),
    productDescription: yup.string().when("step", {
      is: "1",
      then: yup.string().required(),
    }),
    accountType: yup.string().when("step", {
      is: "2",
      then: yup.string().required(),
    }),
    firstName: yup.string().when("accountType", {
      is: "individual",
      then: yup.string().required(),
    }),
    lastName: yup.string().when("accountType", {
      is: "individual",
      then: yup.string().required(),
    }),
    email: yup.string().when("step", {
      is: "2",
      then: yup.string().email().required(),
    }),
    phoneNumber: yup.string().when("step", {
      is: "2",
      then: yup.string().required(),
    }),
    companyName: yup.string().when("accountType", {
      is: "company",
      then: yup.string().required(),
    }),
    companyWebsite: yup.string().when("accountType", {
      is: "company",
      then: yup.string().required(),
    }),
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const watchAccountType = watch("accountType");

  const decreaseStep = () => {
    let changeStep = step - 1;

    if (changeStep < 1) {
      changeStep = 1;
      return;
    }

    setStep(changeStep);
  };

  const onsubmit = async (data) => {
    try {
      const result = await axios({
        method: "POST",
        url: baseURL,
        data: data,
      });

      if (result.status === 201) {
        // toast("You have successfully logged in!", {
        //   position: "top-right",
        //   theme: "colored",
        //   type: "success",
        // });

      
          navigate("/success");
        
      } else {
        toast(result.message, {
          position: "top-right",
          theme: "colored",
          type: "error",
        });
      }
    } catch (error) {}
  };

  const increaseStep = () => {
    if (isValid) {
      setStep(step + 1);
      setValue("step", step);
      return;
    }

    trigger();
  };
  // console.log(watch("step"));

  return (
    <>
      <section className="w-full" style={{ minHeight: "100vh" }}>
        <div className="hidden md:block fixed left-0 top-0 bottom-0 w-400 bg-truck ">
          <section className="w-full mx-auto h-full py-5 bg-[rgba(0,0,0,0.7)] px-8">
            <figure className="logo-container dm-logo border-0">
              <img
                src="/assets/bodales.svg"
                alt="logo"
                className="w-full h-full"
              />
            </figure>

            {/* texts */}

            <div className="w-full h-5/6 flex flex-col justify-center">
              <h1 className=" text-2xl md:text-5xl font-semibold text-center md:text-left w-90 mb-2 mx-auto md:mx-0 text-white">
                Apply <br />
                <span className="text-custom-red">to Bodales</span>
              </h1>

              <p className="text-white text-base font-semibold">
                Get your goods through any border in Africa, easily.
              </p>
            </div>
          </section>
        </div>

        <section className="w-calc md:ml-auto  min-h-screen pt-7">
          {/* progress bars */}

          <Components.ProgressBar step={step} />

          <div className="mt-custom w-custom60 mx-auto pb-5">
            {step === 1 && (
              <>
                <h3 className="text-3xl w-full" style={{ lineHeight: 1.5 }}>
                  Where are you moving from and to?
                </h3>
                <p
                  className="w-full mt-4 text-light-formtext text-sm"
                  style={{ lineHeight: 1.5 }}
                >
                  Start with picking which countries border you’re trading
                  across
                </p>
              </>
            )}

            {step === 2 && (
              <>
                <h3 className="text-3xl w-full" style={{ lineHeight: 1.5 }}>
                  What goods are you moving?
                </h3>
                <p
                  className="w-full mt-4 text-light-formtext text-sm"
                  style={{ lineHeight: 1.5 }}
                >
                  Let’s get you cleared fast, tell us what you’re moving
                </p>
              </>
            )}

            {step === 3 && (
              <>
                <h3 className="text-3xl w-full" style={{ lineHeight: 1.5 }}>
                  How can we reach you?
                </h3>
                <p
                  className="w-full mt-4 text-light-formtext text-sm"
                  style={{ lineHeight: 1.5 }}
                >
                  Lastly, type your business data to complete the application
                </p>
              </>
            )}

            <form
              className="mt-4 w-full mx-auto"
              onSubmit={handleSubmit(onsubmit)}
            >
              {step === 1 && (
                <Components.FormStepOne register={register} error={errors} />
              )}
              {step === 2 && (
                <Components.FormStepTwo register={register} error={errors} />
              )}
              {step === 3 && (
                <Components.FormStepThree
                  register={register}
                  watchAccountType={watchAccountType}
                  error={errors}
                />
              )}

              <div className="buttons mt-7 flex justify-between">
                <div className="back-btn next h-custom bg-transparent rounded-lg overflow-hidden mx-auto md:mx-0 mt-5 cursor-pointer w-150">
                  <button
                    className="flex items-center capitalize w-full h-full"
                    type="button"
                    onClick={decreaseStep}
                  >
                    <FaArrowLeft />
                    <span className="ml-2">back</span>
                  </button>
                </div>

                <div
                  className={`next h-custom rounded-lg overflow-hidden mx-auto md:mx-0 mt-5 cursor-pointer w-150 ${
                    step === 3 ? "bg-apply-bg" : "bg-custom-red"
                  }`}
                >
                  {step === 3 ? (
                    <input
                      type="submit"
                      value={isSubmitting ? "loading..." : "apply"}
                      className="w-full h-full capitalize text-white cursor-pointer"
                    />
                  ) : (
                    <button
                      type="button"
                      className="w-full h-full capitalize text-white"
                      onClick={increaseStep}
                    >
                      next
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </section>
      </section>
    </>
  );
};

export default Register;
