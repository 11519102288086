import React from "react";
import { Link } from "react-router-dom";

const SuccessPage = () => {
  return (
    <section className="h-screen flex  w-full bg-[#408140] items-center justify-center">
      <div className="w-90 mx-auto flex flex-col items-center">
        <h1 className="text-white text-3xl leading-[2] text-center">
          Your application has been received. We'll reach out to you shortly!
        </h1>
      </div>
    </section>
  );
};

export default SuccessPage;
