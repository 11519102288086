import React from "react";

const ProgressBar = ({ step }) => {
  return (
    <div className="flex items-center w-90 mx-auto gap-x-8">
      <div className="bg-custom-red rounded h-1.5 w-full"></div>
      <div
        className={` ${
          step >= 2 ? "bg-custom-red" : "bg-progress-bar"
        } rounded h-1.5 w-full`}
      ></div>
      <div
        className={` ${
          step === 3 ? "bg-custom-red" : "bg-progress-bar"
        } rounded h-1.5 w-full`}
      ></div>
    </div>
  );
};

export default ProgressBar;
