import React from "react";
import * as Inputs from "../inputs";
import { accountType } from "../../data";

const FormStepThree = ({ register, watchAccountType, error }) => {
  return (
    <>
      <Inputs.PrimarySelect
        label="trading as"
        name="accountType"
        options={accountType.map((item, index) => {
          return (
            <option value={item.toLowerCase()} key={index}>
              {item}
            </option>
          );
        })}
        register={register}
        option="--Select account type--"
        error={error?.accountType}
        message="Please select a valid account type"
      />

      {/* {!!watchAccountType && <p>hello world</p>} */}
      {!!watchAccountType && watchAccountType === "individual" && (
        <>
          <Inputs.PrimaryInput
            label="first name"
            name="firstName"
            register={register}
            placeholder="Enter your first name"
            error={error?.firstName}
            message="Please select a valid first name"
          />

          <Inputs.PrimaryInput
            label="last name"
            name="lastName"
            register={register}
            placeholder="Enter your last name"
            error={error?.lastName}
            message="Please select a valid last name"
          />
        </>
      )}

      {!!watchAccountType && watchAccountType === "company" && (
        <>
          <Inputs.PrimaryInput
            label="company name"
            name="companyName"
            placeholder="Enter your company name"
            register={register}
            error={error?.companyName}
            message="Please enter a valid company name"
          />

          <Inputs.PrimaryInput
            label="company website"
            name="companyWebsite"
            register={register}
            placeholder="Enter your website url"
            error={error?.companyWebsite}
            message="Please enter a valid company website"
          />
        </>
      )}

      {!!watchAccountType && (
        <>
          <Inputs.PrimaryInput
            label="email"
            name="email"
            register={register}
            placeholder="Enter your email"
            error={error?.email}
            message="Please select a valid email"
          />

          <Inputs.PrimaryInput
            label="phone number"
            name="phoneNumber"
            register={register}
            placeholder="Enter your phone number"
            error={error?.phoneNumber}
            message="Please enter a valid phone number"
          />
        </>
      )}
    </>
  );
};

export default FormStepThree;
