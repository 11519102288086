import "./App.css";
import { Route, Routes } from "react-router-dom";
import * as Pages from "./pages";
import * as AuthPages from "./pages/auth";
import * as ErrorPages from "./pages/error";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
      <main className="routes w-full ">
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Pages.Home />} />
          <Route path="/register" element={<AuthPages.Register />} />
          <Route path="/success" element={<Pages.SuccessPage />} />
          <Route path="*" element={<ErrorPages.NOtFound />} />
        </Routes>
      </main>
    </>
  );
}

export default App;
